import PREMISSIONS from '../../config/premissions'


export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    access_level: PREMISSIONS.USER,
  },
  {
    title: 'Frota',
    route: 'Frota',
    icon: 'MapPinIcon',
    access_level: PREMISSIONS.USER,
  },
  {
    title: 'Registrations',
    icon: 'FileTextIcon',
    access_level: PREMISSIONS.USER,
    children: [
      {
        title: 'Company',
        route: 'list-companies',
        icon: 'BoldIcon',
        access_level: PREMISSIONS.MASTER,
      },

      {
        title: 'Users',
        route: 'list-users',
        access_level: PREMISSIONS.ADMIN,
      },

      {
        title: 'Centrals',
        route: 'list-centers',
        access_level: PREMISSIONS.ADMIN,
      },

      {
        title: 'Units',
        route: 'list-units',
        access_level: PREMISSIONS.ADMIN,
      },
    
      // {
      //   title: 'Profile Types',
      //   route: 'list-profile_types',
      //   access_level: PREMISSIONS.MASTER,
      // },
      {
        title: 'Insurers',
        route: 'list-insurances',
        access_level: PREMISSIONS.USER,
      },
      {
        title: 'Types Of Fine',
        route: 'list-fine_types',
        access_level: PREMISSIONS.USER,
      },
      {
        title: 'Gas station',
        route: 'list-gasstations',
        access_level: PREMISSIONS.USER,
      },

      
      {
        title: 'Type Of Professional',
        route: 'list-typeprofessionals',
        access_level: PREMISSIONS.ADMIN,
      },
      {
        title: 'Professionals',
        route: 'list-professionals',
        access_level: PREMISSIONS.ADMIN,
      },
    
      // {
      //   title: 'Phone Types',
      //   route: 'list-telephone_type',
      //   access_level: PREMISSIONS.MASTER,
      // },
      // {
      //   title: 'Types of address',
      //   route: 'list-address_type',
      //   access_level: PREMISSIONS.MASTER,
      // },
      
    ],
  },

  {
    title: 'Expenses',
    icon: 'DollarSignIcon',
    access_level: PREMISSIONS.USER,
    children: [
      {
        title: 'Supplies',
        route: 'list-refuelings',
        access_level: PREMISSIONS.USER,
      },
      {
        title: 'Manutenções',
        route: 'list-maintenances',
        access_level: PREMISSIONS.USER,
      },
      {
        title: 'Licensing',
        route: 'list-licensings',
        access_level: PREMISSIONS.USER,
      },
      {
        title: 'Fines',
        route: 'list-fines',
        access_level: PREMISSIONS.USER,
      },
      {
        title: 'Insurance',
        route: 'list-auto_insurances',
        access_level: PREMISSIONS.USER,
      },
     
    ],
  },
  {
    title: 'Vehicles',
    icon: 'TruckIcon',
    access_level:  PREMISSIONS.USER,
    children: [
      {
        title: 'Vehicle Brands',
        route: 'list-vehicle_brands',
        access_level:  PREMISSIONS.USER,
      },
      {
        title: 'Vehicle Models',
        route: 'list-vehicle_models',
        access_level:  PREMISSIONS.USER,
      },
      {
        title: 'Vehicle Colors',
        route: 'list-vehicle_colors',
        access_level:  PREMISSIONS.USER,
      },
      {
        title: 'Vehicle Type',
        route: 'list-vehicle_types',
        access_level:  PREMISSIONS.USER,
      },
      {
        title: 'Vehicles',
        route: 'list-vehicles',
        access_level:  PREMISSIONS.USER,
      },
      
    ]
  },
  {
    title: 'Devices',
    icon: 'DiscIcon',
    access_level:  PREMISSIONS.USER,
    children: [

      {
        title: 'Suppliers',
        route: 'list-supplier',
        access_level:  PREMISSIONS.USER,
      },
      {
        title: 'Devices Model',
        route: 'list-deviceModels',
        access_level:  PREMISSIONS.USER,
      },
      {
        title: 'Operators',
        route: 'list-Operators',
        access_level:  PREMISSIONS.USER,
      },
      {
        title: 'Chips',
        route: 'list-Chips',
        access_level:  PREMISSIONS.USER,
      },
      {
        title: 'Devices',
        route: 'list-devices',
        access_level:  PREMISSIONS.USER,
      },
      {
        title: 'Devices Activation',
        route: 'list-devicesActivation',
        access_level:  PREMISSIONS.USER,
      },
    ]

  },
  {
    title: 'Events',
    icon: 'ZapIcon',
    access_level:  PREMISSIONS.USER,
    children: [
      {
        title: 'Criticality types',
        route: 'list-criticalitytypes',
        access_level:  PREMISSIONS.USER,
      },
      {
        title: 'Event types',
        route: 'list-eventTypes',
        access_level:  PREMISSIONS.USER,
      },
      {
        title: 'Sensors',
        route: 'list-Sensors',
        access_level:  PREMISSIONS.USER,
      },

    ]
  },
  {
    title: 'Area',
    icon: 'MapPinIcon',
    access_level:  PREMISSIONS.USER,
    children: [
      // {
      //   title: 'Types of area',
      //   route: 'list-areatypes',
      //   access_level: PREMISSIONS.MASTER,
      // },
      {
        title: 'Catagories of Area',
        route: 'list-areacatagories',
        access_level:  PREMISSIONS.USER,
      },
      {
        title: 'Area',
        route: 'list-areas',
        access_level:  PREMISSIONS.USER,
      },

    ]
  },
  // {
  //   title: 'operational panel', // Operational Panel
  //   route: 'list-operational_panel',
  //   icon: 'ActivityIcon',
  //   access_level:  PREMISSIONS.MASTER,
  // },
  // {
  //   title: 'Rescue',
  //   icon: 'GlobeIcon',
  //   access_level:  PREMISSIONS.USER,
  //   children: [
  //   ]
  // },

  // {
  //   title: 'Rescue',
  //   icon: 'GlobeIcon',
  //   access_level:  PREMISSIONS.USER,
  //   children: []}
 
  // {
  //   title: 'Chat',
  //   route: 'chat',
  //   icon: 'MessageSquareIcon',
  //   access_level:  PREMISSIONS.USER,
  // },
]
